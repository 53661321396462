/*
 * WooCommerce - Global
**/

.wrapper{

    /** 
    * Notices
    **/
    .woocommerce-notices-wrapper{
        @extend .container;
    }

    .woocommerce-message,
    .woocommerce-info,
    .woocommerce-error{
        border-top: 0;
        border-radius: 5px;
        background-color: rgba(#DADADA, .25);
        line-height: 50px;

        &:before{
            content: "\e015";
            color: $orange !important;        
        }

        .button{
            @extend .woocommerce-btn;                  
        }

        li{
            line-height: 50px;
        }
    }

    /**
     * On sale
    **/
    .woocommerce span.onsale{
        border-radius: 0 !important;
        background-color: $black !important;
        padding: 10px;        
    }

    /**
    * Buttons
    **/
    .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) #respond input#submit.alt, .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) a.button.alt, .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button.alt, .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) input.button.alt, :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce #respond input#submit.alt, :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce a.button.alt, :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce button.button.alt, :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce input.button.alt{
        @extend .woocommerce-btn;
    }

    .single_add_to_cart_button,
    .add_to_cart_button,
    button#place_order,
    .woocommerce .cart .button, .woocommerce .cart input.button,
    a.checkout-button{
        @extend .woocommerce-btn;
    }
}

.woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) #respond input#submit, .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) a.button, .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button, .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) input.button, :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce #respond input#submit, :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce a.button{
    @extend .woocommerce-btn;            
}

.woocommerce-btn{
    border-radius: 5px !important;
    display: inline-block !important;
    font-size: 16px !important;
    font-weight: 400 !important;   
    height: 50px !important;
    min-width: 250px !important;
    text-align: center !important;
    text-decoration: none !important;
    line-height: 50px !important;
    padding: 0 !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    
    transition: all .3s ease !important;         
    background-color: $orange !important;
    border: 1px solid $orange !important;
    color: $white !important;

    &:hover{
        color: $white !important;
    }          
}
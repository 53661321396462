/*
 * WooCommerce - Checkout
**/

#coupon_code{
    width: auto;
    max-width: 200px;
    border: 1px solid $blue;
    margin-top: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: auto;
    margin-right: 0;
    padding-left: 20px;
    height: 38px;    
}

.wrapper .woocommerce-checkout{

    input,
    textarea{
        background-color: rgba(#DADADA, .25);
        border: 0;
        border-radius: 5px;
        padding-left: 15px;
    }

    input:not(.input-radio){
        height: 50px;    
    }

    textarea{
        padding: 15px;
        min-height: 250px;
    }

    form .form-row label{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 10px;
        margin-top: 15px;
    }

    .page-intro{
        margin-bottom: 0; 
    }

    .woocommerce-form-coupon-toggle{
        display: none;
    }

    .woocommerce-billing-fields,
    .woocommerce-additional-fields{
        h3{
            display: none;
        }
    }

    .woocommerce form .form-row .required {
        color: $orange;
    }

    #payment div.payment_box{
        background-color: rgba($latte, .25);
        
        &:before{
            border: rgba($latte, .25);
        }       
    }
}

.woocommerce-cart{
    .page-intro{
        margin-bottom: 0;
    }

    .cart_totals{
        h2{
            margin-bottom: 20px;
            margin-top: 35px;            
        }

        th{
            color: $blue;
            font-weight: 500 !important;
            padding-left: 0 !important;
            width: 60% !important;
        }

        .order-total .woocommerce-Price-amount{
            bdi{
                color: $blue;
            }
        }

        .wc-proceed-to-checkout{
            a.checkout-button{
                background-color: $blue !important;
                border-radius: 5px !important; 
                color: $white !important;
                font-size: 16px !important;
                font-weight: 400 !important;
                border: none !important;
            }
        }
    }
}

.woocommerce .product-remove a.remove{
    color: $blue !important;

    &:hover{
        background-color: rgba(7, 98, 131, 0.20) !important;
        color: $blue !important;
    }
}

.woocommerce-cart-form{
    .product-thumbnail{
        display: none;
    }
}

.woocommerce table.shop_table{
    border: 0 !important;
}

.woocommerce table.shop_table thead{
    color: $white;
    border: 0;
    font-size: 18px;
    line-height: 30px;

    th{
        background-color: $blue;    
    }
}

.woocommerce table.shop_table th{
    font-weight: 400 !important;
    
    &.product-remove{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;        
    }

    &.product-subtotal{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; 
    }
}

.woocommerce .woocommerce-cart-form table.shop_table td{
    border-top: 0 !important;
}

.woocommerce .cart_totals .shop_table{
    tbody{
        border-top: 1px solid #DADADA !important;
        border-bottom: 1px solid #DADADA !important;
    }
}

.woocommerce table.shop_table .woocommerce-cart-form__cart-item{
    &:last-of-type{
        td{
            border-bottom: 0;
        }
    }

    td{
        padding: 25px 12px;
        border-top: 0;
        border-bottom: 1px solid #DADADA;

    }

    .product-name{
        a{
            color: $black;
            font-weight: 400;
            text-decoration: none;
        }
    }
}

.wrapper .woocommerce .woocommerce-cart-form{
    button.button{
        background-color: transparent !important;
        color: #ea6546 !important;
        text-align: center !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        text-decoration-line: underline !important;
        border: 0 !important;   
    }

    .coupon{
        button.button{
            background-color: $blue !important;
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            text-decoration: none !important;
            color: #FFF !important;
            margin-top: 10px;
            margin-left: 0;
            border: 1px solid $blue !important;    

            height: 38px !important;
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            line-height: 20px !important;            
        }
    } 

    .product-name{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;        
    }
}

/**
 * Checkout - Gegevens
**/
.woocommerce form .form-row label, .woocommerce-page form .form-row label {
    font-weight: 400 !important;
    margin-top: 10px !important;
}

#customer_details{
    margin-bottom: 80px;
}

.woocommerce-form-coupon-toggle{
    display: none;
}

#add_payment_method #payment,
.woocommerce-cart #payment,
.woocommerce-checkout #payment{
    background-color: rgba($latte, .25) !important;
}

.woocommerce table.shop_table{
    border-spacing: 0;
}

#order_review_heading{
    color: $orange;
    margin-bottom: 35px;
}

.product-name{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.product-total{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;    
}

/**
 * Checkout - Order ontvangen
**/

.woocommerce-order-received{
    .page-intro{
        margin-bottom: 0;
    }

    .woocommerce table.shop_table tfoot td{
        font-weight: 400;
    }

    .woocommerce td.product-name .wc-item-meta{
        margin-top: 10px;

        .wc-item-meta-label{
            font-weight: 500;
        }
    }

    .woocommerce-order-details__title,
    .woocommerce-column__title{
        color: $orange;
    }

    .woocommerce-customer-details{
        address{
            background-color: $latte-25;
            border: none !important;
            padding: 45px !important;
        }

        &--phone,
        &--email{
            font-weight: 400;
            margin-bottom: 0;
            padding-left: 0 !important;
            line-height: 24px;

            &:before{
                display: none;
            }
        }  
    }  
}
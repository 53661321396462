/**
 * Blog Overview
**/
.blog-entry{
    a{
        text-decoration: none;
    }

    p a{
        text-decoration: underline;
    }    

    h2{
        font-size: 20px;
        font-weight: 400;
        line-height: 32px; /* 160% */
        margin-top: 25px;    

        transition: all .3s ease;
    }

    img{
        transition: all .3s ease;
    }

    &:hover{
        h2{
            color: $orange;
        }

        img{
            transform: scale(1.05);
        }
    }
}

/**
 * Single
**/
.page-intro--blog{
    .author-card{
        .author-info{
            padding: 45px 40px;            
        }

        .author{
            font-size: 16px;
            font-weight: 700;
            line-height: 36px; /* 225% */
            text-transform: uppercase;            
        }
        
        p,
        ul,
        li,
        a{
            font-size: 16px;
            font-weight: 400;
            line-height: 36px;            
        }

        a{
            color: $white;
        }
    }
}
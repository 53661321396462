/**
 * Card
**/

.card{
    .card-info{
        padding: 45px 40px;
    }

    .card-title{
        font-size: 16px;
        font-weight: 700;
        line-height: 36px; /* 225% */
        text-transform: uppercase;            
    }
    
    p,
    ul,
    li,
    a{
        font-size: 16px;
        font-weight: 400;
        line-height: 36px;            
    }

    a{
        color: $white;
    }
}
/**
 * Contact
 **/
.contact-page-form{
    margin-bottom: -80px;

    h2{
        color: $orange;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px; /* 83.333% */         
    }

    .contact-info{
        @include media-breakpoint-down(sm){
            margin-top: 50px;
        }
        
        ul{
            padding-left: 0;
            list-style-type: none;
            margin-top: 55px;
        }
    }

    .wpcf7-form{
        margin-top: 40px;
    }

    .social-icons{
        p{
            margin-top: 35px;
        }

        a{
            text-decoration: none;
        }
    }
}

/**
 * Agenda
 **/

 .agenda-entry{
    border-bottom: 1px solid #DADADA; 
    margin-bottom: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;

    &__date{
        background-color: $white;
        border: 1px solid $orange;
        color: $orange;
        height: 165px;
        width: 135px;
        text-align: center;
        font-size: 22px;
        font-weight: 400;
        line-height: 30px; /* 136.364% */         
        text-transform: uppercase;

        span{
            display: block;
        }
    }

    &__content{
        @include media-breakpoint-up(md){
            margin-left: 40px;
        }
    }

    h2{
        color: $orange;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px; /* 83.333% */        
        margin-bottom: 20px; 
    }

    p,
    a{
        font-size: 16px;
        font-weight: 400;
        line-height: 30px; /* 187.5% */         
    }
 }
/**
 * Newsletter
**/
.newsletter{
    border-top: 1px solid $orange;
    justify-content: center;
    margin-top: 80px;

    h4{
        color: $blue;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px; /* 140% */
        margin-right: 100px;    
    }

    input{
        border: 0;
        border-bottom: 1px solid $blue;
        color: $blue;
        padding-bottom: 10px;
        width: 100%;
        
        @include media-breakpoint-up(lg){
            width: 410px;
        }
    }

    input[type='submit']{
        background-image: url('images/arrow_forward.svg');
        height: 16px;
        width: 16px;
        right: 28px;
        position: relative;
        top: 5px;
        background-color: transparent;
        background-repeat: no-repeat;
        border-bottom: 0;
    }

    @include media-breakpoint-down(md){
        display: block;
        padding: 15px;

        h4{
            margin-right: 0;            
        }

        input[type="email"]{
            width: calc(100% - 30px);            
        }
    }    

}


/**
 * Footer
**/

footer{
    padding-top: 80px;
    padding-bottom: 80px;

    h5{
        font-size: 16px;
        font-weight: 500;
        line-height: 28px; /* 175% */ 
        margin-bottom: 0;
    }

    ul{
        padding-left: 0;
        list-style-type: none;
    }

    a{
        text-decoration: none;
    }

    p{
        font-size: 14px;
        font-weight: 300;
        line-height: 28px;         
    }

    .main-navigation,
    .secondary-navigation,
    .contact-info{
        margin-top: 35px;

        @include media-breakpoint-up(xl){
            margin-top: 0;
        }
    }

    .main-navigation{
        a{
            font-size: 16px;
            font-weight: 500;
            line-height: 32px; /* 200% */             
        }
    }

    .secondary-navigation{
        a{
            font-size: 14px;
            font-weight: 300;
            line-height: 28px; /* 200% */             
        }
    }
}

.footer-final{
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 40px;

    .row{
        border-top: 1px solid $orange;
        padding-top: 25px;        
    }

    a{
        color: $black;
        font-size: 12px;
        font-weight: 400;
        text-decoration: none;
        line-height: 14px;        
    }
}

/**
 * Content blocks
**/

.content-block{
    &--big-text{
        p{
            font-size: 20px;
            font-weight: 300;
            line-height: 36px; /* 180% */ 
            margin-bottom: 45px;
        }
    }

    &--full-w-editor{
        h2{
            color: $orange;
            font-size: 26px;
            font-weight: 400;
            line-height: 38px; /* 150% */            
        }

        h3{
            color: $orange;
            font-size: 24px;
            font-weight: 400;
            line-height: 36px; /* 150% */            
        }

        h4{
            color: $orange;
            font-size: 22px;
            font-weight: 400;
            line-height: 34px; /* 150% */            
        }

        strong{
            font-weight: 700;
        }
    }

    &--quote{
        background-image: url(images/boog-latte.svg);
        background-repeat: no-repeat;
        background-position: center center;
        min-height: 300px;
        margin-top: 50px;
        
        blockquote{
            p:not(.quote__author){
                text-align: center;
                font-size: 24px;
                font-weight: 300;
                line-height: 40px; /* 166.667% */ 
                max-width: 750px;
                margin: 0 auto;
            }
        }
     
        .quote__author{
            font-size: 16px;
            line-height: 40px;
            margin-top: 10px;
            text-transform: uppercase;
            text-align: center;

            span{
                position: relative;

                &:before{
                    content:'';
                    position: absolute;
                    left: -20px;
                    top: 9px;
                    height: 1px;
                    width: 15px;
                    background-color: $black;
                }
            }
        }
               
    }

    &--bg-txt-image,
    &--txt-image{
        .content-block__inner{
            padding: 45px;
            margin-bottom: 45px;
        }

        h2{ 
            font-size: 24px;
            font-weight: 400;
            line-height: 36px; /* 150% */
            margin-bottom: 25px;
        }          
    }

    &--bg-txt-image{
        h2{
            @include media-breakpoint-down(sm){
                margin-top: 35px;
            }
        }
    }

    &--txt-image{
        img{
            @include media-breakpoint-down(sm){
                margin-top: 35px;
            }
        }
    }

}
/**
 * Team
**/

.team-entry{
    padding: 45px;
    margin-bottom: 45px;

    h2{ 
        font-size: 24px;
        font-weight: 400;
        line-height: 36px; /* 150% */
        margin-bottom: 25px;
    }
}
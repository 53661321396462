/**
 * Global variables
 **/
$image_path: 'images/';

/**
 * Fonts
**/
$body-font: "Inter", sans-serif;
$heading-font: $body-font;

/**
 * Colors
 **/
$blue: #076283;
$blue-50: rgba($blue, .50);
$latte: #DCDAC1;
$latte-25: rgba($latte, .25);
$latte-80: rgba($latte, .80);
$orange: #EA6546;

$primary-color: #FFFFFF;
$secondary-color: #FFFFFF;

$white: #FFFFFF;
$black: #000000;


/**
 * Transitions
 **/
$animation-bezier: cubic-bezier(.5,0,.25,1);
/** 
 * Social media topper
**/
.social-media{
	border-bottom: 1px solid $orange;
	color: $orange;
	font-size: 14px; 
	padding-top: 11px;
	padding-bottom: 12px;

	.content-wrapper{
		text-align: right;		
	}

	span{
		margin-right: 15px;
	}

	a{
		text-decoration: none;
	}

	img{
		margin-left: 3px;
	}
}

/**
 ** Navbar
**/

.navbar-top-wrapper{
	margin-top: 25px;
    position: relative;
    z-index: 1337;	
}

.nav-top{
	display: none;

	@include media-breakpoint-up(lg){
		display: block;
	}

	ul{
		float: right;
	}

	a{
		color: rgba(0, 0, 0, 0.60);
		font-size: 14px;
		font-weight: 400;

		transition: all .3s ease;

		&:hover{
			color: $orange;
		}		
	}

	.basket-link{
		position: relative;
		bottom: 5px;
		padding-right: 0;		
	}
}

.navbar-nav{
	.nav-link{
		color: $black;
		font-size: 16px;
		font-weight: 400;
		margin-left: 25px;

		transition: all .3s ease;

		&:hover{
			color: $orange;
		}
	}
}

#menu-entries {
	flex-direction: row-reverse;
}

#nav-main{
	margin-top: 25px;
	margin-bottom: 25px;	

	@include media-breakpoint-up(lg){
		margin-top: -20px;		
	}

	&.not-front{
		border-bottom: 1px solid $orange;
		padding-bottom: 35px;
		margin-bottom: 30px;		
	}
}

.hdr-logo-link{
	img{
		margin-top: -25px;
	}
}

/**
 * Mobile only
 **/

@include media-breakpoint-down(lg) {
	#menu-entries{
		display: none !important;
		margin-top: 30px;
	
		&.show{
			display: block !important;
			width: 100% !important;
		}

		ul{
			width: 100%;
			display: block;
		}

		.nav-link{
			margin-left: 0;
			padding-left: 0;
		}
	}

	.navbar{
		display: block !important;
	}

	.navbar-toggler{
		float: right;
	}
}


#menu-entries{
	.dropdown.child{
		position: relative !important;
		.dropdown-sub{
			position: absolute !important;
			right: 0;
		}
	}
}

/**
 * Mobile toggler
 **/
.navbar-toggler{
	border: 0;
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(234, 101, 70, 1.0)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.row:before, .row:after {
	display: none !important;
}

.article-body{
    h2{
        color: $blue;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;        
    }

    a{
        color: $blue;
        font-size: 16px;
        line-height: 30px;
        text-decoration-line: underline;        
    }
}
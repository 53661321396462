/**
 * Call to action
**/
.call-to-action{
    &__content{
        background-image: url('images/cta-bg.svg');
        background-size: cover;
        background-repeat:no-repeat;
        background-blend-mode: multiply;
        padding-left: 30px;
        padding-right: 30px;
    }

    h3{
        font-weight: 400;
        max-width: 840px;
        margin: 0 auto;
        margin-bottom: 25px;

        @include media-breakpoint-up(lg){
            font-size: 38px;
            line-height: 48px; /* 126.316% */
        }
    }

    p{
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 36px; /* 180% */    
        margin-bottom: 40px;
        
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}
/*
 * WooCommerce - Product
**/

/* Price */
.woocommerce:where(body:not(.woocommerce-uses-block-theme)) ul.products li.product .price{
    color: $black !important;
}

/* On sale label */
.entry-images{
    position: relative;
    margin-top: 45px;

    @include media-breakpoint-up(md){
        margin-top: 0;
    }

    span.onsale{
        border-radius: 0 !important;
        background-color: $black !important;
        padding: 10px;         
    }
}

.single-product-details{
    margin-bottom: 75px;

    .price{
        display: none;
    }

    .product_meta{
        display: none;
    }

    .woocommerce-product-details__short-description{
        margin-bottom: 30px;
    }

    .single_add_to_cart_button{
        @extend .btn;
        @extend .btn--orange;

        background-color: $orange !important;
        border-radius: 5px !important;
        font-size: 16px !important;
        font-weight: 400 !important;   
        height: 50px !important;
    }

    .variations th{
        display: none;
    }

    select#datum{
        width: 250px;
        height: 50px;
        text-align: center;
        border: 1px solid $blue;
        border-radius: 5px;
        color: $blue;
        appearance: none; 
    }

    .single_variation_wrap{
        margin-top: 20px;
    }

    .reset_variations{
        display: none !important;
    }
}

/**
 * Product extra info
**/
.product-extra-info{
    h3{
        color: $blue;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px; /* 83.333% */                 
        margin-top: 50px;
        margin-bottom: 30px;        
    }
}
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// 4. Include any optional Bootstrap components as you like
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/accordion";
/* Niet alle bootstrap elementen zijn momenteel toegevoegd: alle zijn te vinden in /node_modules/bootstrap/scss/ */

// 5. Add additional custom code here
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

// Global
@import "lib/global/variables";
@import "lib/global/typography";

// Modules
@import "lib/modules/mixins";
@import "lib/modules/fonts";
@import "lib/modules/forms";
@import "lib/modules/contentblocks";

// Partials
@import "lib/partials/breadcrumbs";
@import "lib/partials/body";
@import "lib/partials/card";
@import "lib/partials/calltoaction";
@import "lib/partials/wordpress";
@import "lib/partials/utils";
@import "lib/partials/wrapper";
@import "lib/partials/header";
@import "lib/partials/nav";
@import "lib/partials/sidebar";
@import "lib/partials/footer";
@import "lib/partials/contentblocks";
@import "lib/partials/page-intro";

// WooCommerce
@import "lib/woocommerce/account";
@import "lib/woocommerce/global";
@import "lib/woocommerce/loop";
@import "lib/woocommerce/product";
@import "lib/woocommerce/checkout";

// Templates
@import "lib/templates/front";
@import "lib/templates/team";
@import "lib/templates/agenda";
@import "lib/templates/blog";
@import "lib/templates/faq";
@import "lib/templates/contact";


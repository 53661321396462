/**
 * Front
**/

/**
 * Elevator pitch
**/
.elevator-pitch{
    background-image: url('images/boog-latte.svg');
    background-repeat: no-repeat;
    background-position: center center;    

    &.block-50{
        padding-top: 150px;
    }

    h2{
        margin-bottom: 45px;
    }

    p{
        max-width: 535px;
        margin: 0 auto;
        margin-bottom: 25px;
    }
}

/**
 * Aanbod
**/
.aanbod-entry{
    background-size: 100%;
    background-position: center;
    height: 550px;
    position: relative;
    transition: all .6s $animation-bezier;
    margin-bottom: 25px;

    @include media-breakpoint-up(lg){
        margin-bottom: 0;
    }

    &:hover{
        background-size: 105%;
    }

    .image-wrapper{
        padding: 40px;
        position: relative;
        height: 100%;

        &:before{
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 20.23%, rgba(255, 255, 255, 0) 100%);
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    h3{
        color: $white;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px; /* 150% */
        text-transform: uppercase; 
        position: relative;
        z-index: 1337;

        span{
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 36px; /* 225% */
        }        
    }

    p{
        margin-bottom: 0;
    }

    a{
        text-decoration: none;
    }

    .btn{
        border-radius: 0;
    }
}

/**
 * USPS
**/
.usps{
    background-image: url('images/usps-bg.svg');
    background-position: center left;
    background-repeat: no-repeat;
    margin-bottom: -80px;

    h2{
        margin-bottom: 40px;
    }

    .usp-left{
        p{
            font-size: 20px;
            margin-bottom: 80px;
            line-height: 36px;
        }
    }


    h3{
        margin-bottom: 30px;
    }

    .usp{
        border-bottom: 1px solid $orange;
        padding-bottom: 15px;
        margin-bottom: 35px;

        &:last-of-type{
            border: none;
            padding-bottom: 0px;
            margin-bottom: 0px;            
        }

        p{
            max-width: 500px;
        }
    }
}

/**
 * Testimonials
 **/
.testimonials{
    background-image: url(images/boog-latte.svg);
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 300px;
    
    p{
        text-align: center;
        font-size: 24px;
        line-height: 40px; /* 166.667% */ 
        max-width: 750px;
        margin: 0 auto;
    }

    .testimonial{
        &__author{
            font-size: 16px;
            line-height: 40px;
            margin-top: 10px;
            text-transform: uppercase;

            span{
                position: relative;

                &:before{
                    content:'';
                    position: absolute;
                    left: -20px;
                    top: 9px;
                    height: 1px;
                    width: 15px;
                    background-color: $black;
                }
            }
        }
    }
}

.slick-dots{
    display: inline-block;
    position: absolute;
    bottom: -70px;
    width: calc(100% - 24px);
    text-align: center;
    padding-left: 0; 

    li{
        display: inline;

        button{
            font-size: 0;
            height: 15px;
            width: 15px;
            border: 1.5px solid $black;
            border-radius: 15px;
            background: transparent;
            margin-right: 10px;
            margin-left: 10px;  
            transition: background .3s ease-in;          
        }

        &.slick-active{
            button{
                background: $black;
            }
        }
    }
}
/**
 * Utils
**/

/**
 * Backgrounds & Text Colors
 **/
 @each $name, $color in (
    'blue': $blue,
    'latte': $latte,
    'latte-25': $latte-25,
    'latte-80': $latte-80,
    'orange': $orange,
    'white': $white,
    'black': $black,
    ) {
	.bg--#{$name} {
		background-color: $color;
  	}

    .t--#{$name} {
		color: $color;
  	}
}

/**
 * Blocks
**/
@each $name, $size in ('10': 10, '20': 20, '25': 25, '35': 35, '50': 50, '75': 75, '100': 100, '120': 120, '150': 150) {
	$mobile-size: calc($size/2);
    
    .block-#{$name} {
		padding-top: #{$size}px;
		padding-bottom: #{$size}px;
  	}

    @include media-breakpoint-down(md){
        .block-#{$name} {
            padding-top: #{$mobile-size}px;
            padding-bottom: #{$mobile-size}px;
          }        
    }
}

.block-120{
    @include media-breakpoint-down(xl){
        padding-bottom: 60px;
        padding-top: 60px;
    }
}


/**
 * Helper Classes
**/
.w-100{
    width: 100%;
}

.flex{
    display: flex;
    align-items: center;	
}

.align-center{
	align-content: center;
}

.text-center{
    text-align: center !important;
}

.mb-50{
    margin-bottom: 50px;
}

.mb-80{
    margin-bottom: 80px;
}

.mb-100{
    margin-bottom: 100px;
}

.border-5{
    border-radius: 5px;
    overflow: hidden;
}

.border-l-5{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;
}

.border-r-5{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

.sticky-bottom{
    position: absolute;
    bottom: 0;
    left: 0;
}

.mobile-only{
    display: none !important;

    @include media-breakpoint-down(lg){
        display: inherit !important;
    }
}
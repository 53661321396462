/**
 * Header
**/
.header{
    &--home{
        background-image: url('images/home-header.png');
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;

        @include media-breakpoint-down(sm){
            background-position: bottom right;
            padding-bottom: 270px;
        }

        position: relative;

        &:after{
            content: "";
            position: absolute;
            width: 75%;
            background-color: $latte;
            height:100%;
            right: 0;
            top: 0;

            transition: all 1.2s ease;

            animation-name: reveal-in;
            animation-timing-function: $animation-bezier;
            animation-fill-mode: forwards;
            animation-duration: 3.6s;
            animation-delay: 0.5s;             
        }

        h1{
            margin-bottom: 50px;

            span{
                display: block;
                font-size: 16px;
                font-weight: 400;
                line-height: 36px; /* 225% */
                text-transform: uppercase;                 
            }
        }
    }

    &__content{
        position: relative;
        z-index: 1337;
    }

    .arrow-link{
        color: $orange;
        display: block;
        font-size: 20px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 25px;
        text-decoration: none;
        position: relative;
        margin-right: 30px;
        transition: all .6s $animation-bezier;
        position: relative;
        display: inline-block;

        &:before{
            content: '';
            position: absolute;
            background-image: url('images/arrow-link.svg');
            width: 23px;
            height: 13px;
            right: -30px;
            top: 7px;
            transition: all .6s $animation-bezier;

        }    

        &:after{
            content:'';
            position: absolute;
            width: 0%;
            background-color: $orange;
            bottom: -3px;
            height: 1.5px;
            left: 0;
            opacity: 0;        
    
            transition: all .6s ease;
        }
    
        &:hover{
            &:before{
                transform: translateX(10px);
            }

            &:after{
                opacity: 1;
                width: 100%;
            }
        }        
    }
}

@keyframes reveal-in{
    from {width: 75%;}
    to {width: 0%; }  
}
/*
 * WooCommerce - Loop
**/

.product-entry{
    padding: 25px;
    margin-bottom: 50px;

    @include media-breakpoint-up(md){
        padding: 45px;
    }

    .wp-post-image{
        @extend .border-5;
    }

    .entry-tag{
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 36px; /* 225% */
        margin-bottom: 10px;
        margin-top: 25px;
        text-transform: uppercase;        

        @include media-breakpoint-up(md){
            margin-top: 0;
        }
    }

    .entry-title{
        margin-bottom: 25px;

        a{
            color: $orange;
            font-size: 24px;            
            font-weight: 400;
            line-height: 20px; /* 83.333% */            
            text-decoration: none;
        }
    }

    .entry-preview{
        max-width: 545px;        
        margin-bottom: 50px;
    }

    .button{
        @extend .btn;
    }
}

/**
 * Aanbod voor mannen
 **/
.term-aanbod-voor-mannen{
    .product-entry{
        .entry-title a{
            color: $blue;
        }
    }
}

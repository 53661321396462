/**
 * Mijn account
**/

/**
 * Login
**/
.woocommerce-form.woocommerce-form-login{
    border: 0 !important;
    padding: 45px;
    background-color: rgba($latte, .25);

    input:not(.woocommerce-form__input-checkbox){
        background-color: $white;
        border: 0;
        border-radius: 5px;
        padding-left: 15px;
        height: 50px;        
    }

    button.button{
        background-color: $orange !important;
        border-radius: 5px !important;
        color: $white !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        border: none !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        height: 50px !important;
        min-width: 250px !important;        
    }    
}

/**
 * Sidebar
 **/
 .woocommerce-MyAccount-navigation{
    ul{
        @extend .border-5;
        @extend .bg--latte-25;

        display: inline-block;
        padding: 45px;
        list-style-type: none;
        margin-bottom: 0;
        padding-bottom: 35px;  

        @include media-breakpoint-down(lg){
            width: 100%;
            margin-bottom: 45px;            
        }

        a{
            text-decoration: none;
        }
    }

 }

 .woocommerce-MyAccount-navigation-link--downloads{
    display: none;
 }

/*
 * Bestellingen
 **/
.woocommerce{
    .woocommerce-MyAccount-orders{
        &.shop_table thead th{
            background-color: transparent;
            color: $blue;
        }
    }
}

.woocommerce-account{
    .woocommerce{
        /**
         * Titels
        **/
        .woocommerce-order-details__title,
        .woocommerce-column__title{
            color: $orange;
            margin-top: 35px;
        }

        /**
         * Tabel
        **/
        .woocommerce-table--order-details{
            border-spacing: 0;

            .product-name{
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            .product-total{
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        table.shop_table tfoot td{
            font-weight: 400;
        }

        .product-quantity{
            font-weight: 500;
        }

        .wc-item-meta{
            margin-top: 10px;
        }

        /**
         * Adresgegevens
        **/
        .woocommerce-customer-details{
            address{
                background-color: $latte-25;
                border: none;
                padding: 45px;
            }

            &--phone,
            &--email{
                font-weight: 400;
                margin-bottom: 0;
                padding-left: 0 !important;
                line-height: 24px;

                &:before{
                    display: none;
                }
            }
        }
    }
} 


/**
 * Adres
 **/
.woocommerce-Address{
    width: 100%;

    h3{
        color: $orange;
        margin-top: 50px;
    }

    address{
        margin-top: 15px;
        font-weight: 300;        
    }
}


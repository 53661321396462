/**
 * Page intro
**/
.page-intro{
    @include media-breakpoint-up(md){
        margin-bottom: 80px;
    }

    h1{
        border-bottom: 1px solid $black;
        font-weight: 300;
        padding-bottom: 25px;
        margin-bottom: 60px;
        
        @include media-breakpoint-up(lg){
            font-size: 38px;
            line-height: 48px; /* 126.316% */         
        }
    }

    p{
        font-size: 20px;
        font-weight: 300;
        line-height: 36px; /* 180% */         
    }

    /**
     * Big intro used for - Blog single & Team single 
    **/
    &--big-img-r{
        margin-top: -30px;
    
        .breadcrumbs{
            margin-bottom: 50px;
        }
    
        h1{
            span{
                color: $orange;
                font-size: 16px;
                font-weight: 400;
                line-height: 36px;
                text-transform: uppercase;
                display: block;
            }
        }
    }    
}
/**
 * Forms
 **/

/**
 * Placeholder
**/
::placeholder {
    color: $blue-50;
    opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
    color: $blue-50;
}

/**
 * Forms
 **/
 .wpcf7,
 .woocommerce-MyAccount-content{

    label{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 10px;
        margin-top: 15px;
    }

    input,
    textarea{
        background-color: $white;
        border: 0;
        border-radius: 5px;
        padding-left: 15px;
        width: 100%;
    }
    
    input{
        height: 50px;
    }
    
    textarea{
        padding: 15px;
        min-height: 250px;
    }

    input[type='submit']{
        @extend .btn;
        @extend .btn--orange-border;

        width: 200px;
        background-color: transparent;
        text-transform: uppercase;
    }
}

.woocommerce-MyAccount-content{
    input,
    textarea{
        background-color: rgba(#DADADA, .25);
    }

    legend{
        margin-top: 50px;
    }

    button[type='submit']{
        @extend .btn;
        @extend .btn--orange;

        margin-top: 50px;
        width: 250px;
    }    
}

/**
 * Typography
**/

*{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
}

body{
    font-family: $body-font;  
}

h1,
h2,
h3,
h4,
h5,
h6{
    font-family: $heading-font;
    font-weight: 400;
}

h1,
h2{
    font-weight: 300;    
}

h1{ 
    @include media-breakpoint-up(lg){
        font-size: 44px;    
        line-height: 54px; /* 122.727% */     
    }
}

h2{
    @include media-breakpoint-up(lg){
        font-size: 38px;
        line-height: 48px; /* 105.263% */     
    }
}

h3{
    @include media-breakpoint-up(lg){
        font-size: 24px;
        line-height: 20px; /* 83.333% */         
    }
}

p,
ul,
li,
a,
ol{
    font-size: 16px;
    font-weight: 300;
    line-height: 30px; /* 187.5% */       
}

a{
    color: $black;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    transition: all .3s ease;

    &:hover{
        color: $orange;
    }
}

.entry-tag{
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 10px;
    text-transform: uppercase;
}


.entry-title{
    color: $blue;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 50px;
}

/**
 * Buttons
**/

.btn{
    border-radius: 5px;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;   
    height: 50px;
    min-width: 250px;
    text-align: center;
    text-decoration: none;
    line-height: 50px;
    padding-left: 40px;
    padding-right: 40px;
    
    transition: all .3s ease;

    &--orange-border{
        border: 1px solid $orange;
        color: $orange;

        &:hover{
            background-color: $orange;
            color: $white;
        }
    }

    &--blue-border{
        border: 1px solid $blue;
        color: $orange;
    }

    &--white-border{
        border: 1px solid $white;
        color: $white;

        &:hover{
            background-color: $white;
            color: $blue;
        }
    }

    &--orange{
        background-color: $orange;
        border: 1px solid $orange;
        color: $white;

        &:hover{
            color: $white;
        }
    }

    &--blue{
        background-color: $blue;
        border: 1px solid $blue;
        color: $white;

        &:hover{
            color: $white;
        }
    }

    &--latte{
        background-color: $latte;
        border: 1px solid $latte;
        color: $black;

        &:hover{
            color: $black;
        }
    }    

    &--arrow{
        span{
            position: relative;
            margin-left: -17.5px;

            &:before{
                content: '';
                position: absolute;
                background-image: url('images/arrow-link-white.svg');
                width: 23px;
                height: 13px;
                right: -30px;
                top: 3px;
                transition: all .6s $animation-bezier;
            }    
        }

        &:hover{
            span{
                &:before{
                    transform: translateX(10px);
                }
            }
        } 
        
        &.btn--latte{
            span{
                &:before{
                    background-image: url('images/arrow-link-black.svg') !important;

                }
            }
        }
    }

}
/**
 * Veelgestelde vragen
 **/

 /**
  * Accordion
 **/
.accordion-wrapper{
    margin-top: -50px;
    margin-bottom: 75px;    
}

.accordion{
    .accordion-item{
        border-bottom: 1px solid $black !important;
    }

    .accordion-button{
        padding-top: 25px;
        padding-bottom: 25px;        
    }

    h2 button{
        color: $black;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px; /* 83.333% */         
    }

    p{
        font-size: 16px;
        font-weight: 300;
        line-height: 30px; /* 187.5% */         
    }

    .accordion-button:not(.collapsed){
        background-color: transparent;
        color: $orange;
        outline: none;
    }
}
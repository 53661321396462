/**
 * Breadcrumbs
**/

.breadcrumbs{
    margin-top: 25px;
    margin-bottom: 25px;

    @include media-breakpoint-up(md){
        margin-bottom: 80px;
    }

    ul{
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
    }

    li{
        color: rgba(26, 55, 76, 0.50);
        display: inline;
        font-size: 12px;
        text-transform: uppercase; 
        margin-left: 12px;
        line-height: 12px;
        position: relative;

        &:before{
            content: '•';
            position: absolute;
            left: -10px;
            top: 0px;
              
        }

        a{
            font-size: 12px;
            text-decoration: none;            
        }
    }

    a{
        color: rgba(26, 55, 76, 0.50);        
    }

    &__home-link{
        margin-left: 0px !important;

        &:before{
            display: none;
        }
        
        a{
            font-size: 16px;
        }
    }
}